import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.top-pickup-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.top-plan-slide').slick({
  dots: false,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1280,
    settings: {
      centerMode: true
    }
  }, {
    breakpoint: 1000,
    settings: {
      variableWidth: false,
      centerMode: true,
      centerPadding: '42px'
    }
  }]
});

$('.concept-reason-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      variableWidth: true,
      centerMode: false,
      infinite: false
    }
  }]
});

$('.concept-contents-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      variableWidth: true,
      centerMode: false,
      infinite: false
    }
  }]
});

$('.dress-dresscontent-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.item-media-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      centerMode: true
    }
  }]
});

$('.cuisine-gallery-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  centerMode: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1001,
      settings: {
        centerPadding: '25px',
      },
    },
  ],
});

$('.ceremony-outline-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.commonreport-slide').slick({
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      centerMode: true
    }
  }]
});

$('.facility-gallery-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  centerMode: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1001,
      settings: {
        centerPadding: '25px',
      },
    },
  ],
});

$('.small-wedding-reason-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});
